export const GET_BOARD = "GET_BOARD";
export const GET_BOARD_SUCCESS = "GET_BOARD_SUCCESS";
export const GET_BOARD_FAILURE = "GET_BOARD_FAILURE";
export const EDIT_BOARD = "EDIT_BOARD";
export const ADD_BOARD = "ADD_BOARD";
export const HIDE_BOARD_MODAL = "HIDE_BOARD_MODAL";

export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const HIDE_CATEGORY_MODAL = "HIDE_CATEGORY_MODAL";

export const EDIT_BOOKMARK = "EDIT_BOOKMARK";
export const ADD_BOOKMARK = "ADD_BOOKMARK";
export const HIDE_BOOKAMRK_MODAL = "HIDE_BOOKAMRK_MODAL";
export const CREATE_NEW_BOOKMARK_TO_STORE = "CREATE_NEW_BOOKMARK_TO_STORE";
export const UPDATE_BOOKMARK_TO_STORE = "UPDATE_BOOKMARK_TO_STORE";
export const REMOVE_BOOKMARK_FROM_STORE = "REMOVE_BOOKMARK_FROM_STORE";

export const SAVE_BOARD_TO_STORE = "SAVE_BOARD_TO_STORE";

export const CREATE_NEW_CATEGORY_TO_STORE = "CREATE_NEW_CATEGORY_TO_STORE";
export const UPDATE_CATEGORY_TO_STORE = "UPDATE_CATEGORY_TO_STORE";
export const REMOVE_CATEGORY_FROM_STORE = "REMOVE_CATEGORY_FROM_STORE";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const APPLY_BOARD_REORDER_TO_STORE = "APPLY_BOARD_REORDER_TO_STORE";

export const SET_THEME = "SET_THEME";

const apiBase = process.env.REACT_APP_LINKLEY_API_BASE_URL;

export const getBoard = () => ({ type: GET_BOARD });

export const getBoardSuccess = (board) => ({
  type: GET_BOARD_SUCCESS,
  payload: board,
});

export const getBoardFailure = () => ({ type: GET_BOARD_FAILURE });

export const saveBoardToStore = (boardDetails) => ({
  type: SAVE_BOARD_TO_STORE,
  payload: boardDetails,
});

export const saveBoardChanges = (boardDetails) => {
  return async (dispatch) => {
    const { isAdd } = { ...boardDetails };
    const boardDetailsOnly = { ...boardDetails };
    if (isAdd) {
      dispatch(createNewBoard(boardDetailsOnly));
    } else {
      dispatch(saveBoardToStore(boardDetailsOnly));
      dispatch(updateBoardChanges());
    }
  };
};

export const createNewCategoryToStore = (category) => ({
  type: CREATE_NEW_CATEGORY_TO_STORE,
  payload: category,
});

export const updateCategoryToStore = (category) => ({
  type: UPDATE_CATEGORY_TO_STORE,
  payload: category,
});

export const saveCategoryChanges = (categoryDetails) => {
  return async (dispatch) => {
    const { isAdd } = { ...categoryDetails };

    const category = {
      categoryId: categoryDetails.categoryId,
      categoryName: categoryDetails.categoryName,
      bookmarks: categoryDetails.bookmarks,
    };

    if (isAdd) {
      dispatch(createNewCategoryToStore(category));
      dispatch(updateBoardChanges());
    } else {
      dispatch(updateCategoryToStore(category));
      dispatch(updateBoardChanges());
    }
  };
};

export const removeCategoryFromStore = (category) => ({
  type: REMOVE_CATEGORY_FROM_STORE,
  payload: category,
});

export const removeCategoryChanges = (categoryDetails) => {
  return async (dispatch) => {
    dispatch(removeCategoryFromStore(categoryDetails));
    dispatch(updateBoardChanges());
  };
};

export const addBoard = () => ({
  type: ADD_BOARD,
});

export const editBoard = (board) => ({
  type: EDIT_BOARD,
  payload: board,
});

export const hideBoardModal = () => ({ type: HIDE_BOARD_MODAL });

export function addNewCategory() {
  return async (dispatch) => {
    //dispatch(getBoard()); todo
    try {
      const uuid = await fetchNewUUID();

      dispatch(addCategory(uuid));
    } catch (error) {
      dispatch(getBoardFailure()); //todo
    }
  };
}

export const createNewBookmarkToStore = (details) => ({
  type: CREATE_NEW_BOOKMARK_TO_STORE,
  payload: details,
});

export const updateBookmarkToStore = (details) => ({
  type: UPDATE_BOOKMARK_TO_STORE,
  payload: details,
});

export const removeBookmarkFromStore = (details) => ({
  type: REMOVE_BOOKMARK_FROM_STORE,
  payload: details,
});

export const saveBookmarkChanges = (details) => {
  return async (dispatch) => {
    const { isAdd } = { ...details };

    if (isAdd) {
      dispatch(createNewBookmarkToStore(details));
      dispatch(updateBoardChanges());
    } else {
      dispatch(updateBookmarkToStore(details));
      dispatch(updateBoardChanges());
    }
  };
};

export const removeBookarkFromStore = (details) => ({
  type: REMOVE_BOOKMARK_FROM_STORE,
  payload: details,
});

export const removeBookmarkChanges = (bookmark, categoryId) => {
  return async (dispatch) => {
    dispatch(removeBookmarkFromStore({ ...bookmark, categoryId: categoryId }));
    dispatch(updateBoardChanges());
  };
};

export const addCategory = (categoryId) => ({
  type: ADD_CATEGORY,
  payload: categoryId,
});

export const editCategory = (category) => ({
  type: EDIT_CATEGORY,
  payload: category,
});

export const hideCategoryModal = () => ({ type: HIDE_CATEGORY_MODAL });

export function addNewBookmark(category) {
  return async (dispatch) => {
    //dispatch(getBoard()); todo
    try {
      const uuid = await fetchNewUUID();

      const bookmarkIds = {
        categoryId: category.categoryId,
        bookmarkId: uuid,
      };

      dispatch(addBookmark(bookmarkIds));
    } catch (error) {
      dispatch(getBoardFailure()); //todo
    }
  };
}

export const addBookmark = (ids) => ({
  type: ADD_BOOKMARK,
  payload: ids,
});

export const editBookmark = (bookmark, categoryId) => ({
  type: EDIT_BOOKMARK,
  payload: { ...bookmark, categoryId: categoryId },
});

export const hideBookmarkModal = () => ({ type: HIDE_BOOKAMRK_MODAL });

export function fetchBoard(boadrId) {
  return async (dispatch) => {
    dispatch(getBoard());

    const getBoardUrl = boadrId
      ? `${apiBase}/api/board/${boadrId}`
      : `${apiBase}/api/board/demo`;

    try {
      const response = await fetch(getBoardUrl, { credentials: "include" });

      const data = await response.json();
      dispatch(getBoardSuccess(data));
    } catch (error) {
      dispatch(getBoardFailure());
    }
  };
}

export async function fetchNewUUID() {
  try {
    const response = await fetch(`${apiBase}/api/utils/uuid`);
    const data = await response.text();
    return data;
  } catch (error) {
    return null;
  }
}

export function createNewBoard(boardDetails) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${apiBase}/api/board`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(boardDetails),
      });
      const responseData = await response.json();
      const { boardId } = { ...responseData };
      dispatch(hideBoardModal());
      window.location.href = `/board/${boardId}`;
    } catch (error) {
      return null;
    }
  };
}

export function updateBoardChanges() {
  return async (dispatch, getState) => {
    try {
      const requestData = getState().board.board;

      const response = await fetch(`${apiBase}/api/board`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const responseData = await response.json();
      const { boardId } = { ...responseData };
      dispatch(fetchBoard(boardId));
      dispatch(hideBoardModal());
      dispatch(hideCategoryModal());
      dispatch(hideBookmarkModal());
    } catch (error) {
      return null;
    }
  };
}

export const getUser = () => ({ type: GET_USER });

export const getUserSuccess = (user) => ({
  type: GET_USER_SUCCESS,
  payload: user,
});

export function fetchUser() {
  return async (dispatch) => {
    fetch(`${apiBase}/auth/login/user`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    })
      .then((response) => {
        if (response.status === 200) return response.json();
        throw new Error("failed to authenticate user");
      })
      .then((responseJson) => {
        if (responseJson) {
          dispatch(getUserSuccess(responseJson.user));
        }
      })
      .catch((error) => {
        console.error("fetchUser", error);
        return null;
      });
  };
}

export const applyBoardReorderToStore = (dndResult) => ({
  type: APPLY_BOARD_REORDER_TO_STORE,
  payload: dndResult,
});

export const saveBoardReorder = (dndResult) => {
  return async (dispatch) => {
    dispatch(applyBoardReorderToStore(dndResult));
    dispatch(updateBoardChanges());
  };
};

export const setTheme = (themeName) => {
  localStorage.setItem("theme", themeName);

  return {
    type: SET_THEME,
    payload: themeName,
  };
};
