import React from "react";
import "./App.scss";
import { BoardPage } from "./pages/BoardPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route exact path="/" component={BoardPage} />
          <Route path="/board/:boardId" component={BoardPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
