import React from "react";
import { useSelector } from "react-redux";

export const Loading = () => {
  const loading = useSelector((state) => state.board.loading);

  return (
    <div className={`loading ${loading && "show"}`}>
      <div className="loading__spinner"></div>
    </div>
  );
};
