import React from "react";

export const SubBookmark = ({ subBookmark }) => {
  return (
    subBookmark && (
      <a
        href={subBookmark.subBookmarkUrl}
        target="_blank"
        className="bookmark__child_item"
        rel="noopener noreferrer"
      >
        {subBookmark.subBookmarkName}
      </a>
    )
  );
};
