import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "react-modal";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage, getIn } from "formik";
import { hideBoardModal, saveBoardChanges } from "../actions/boardActions";

const boardSchema = Yup.object({
  boardName: Yup.string().required("Required"),
});

export const AddEditBoardModal = () => {
  const dispatch = useDispatch();
  const showAddEditBoardModal = useSelector(
    (state) => state.board.showAddEditBoardModal
  );
  const currentBoard = useSelector((state) => state.board.currentBoard);
  const user = useSelector((state) => state.board.user);

  ReactModal.setAppElement("#root");

  const getStyles = (errors, fieldName) => {
    if (getIn(errors, fieldName)) {
      return "error-field";
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(saveBoardChanges(values));
  };

  const closeModal = () => {
    dispatch(hideBoardModal());
  };

  return (
    <ReactModal
      isOpen={showAddEditBoardModal}
      onRequestClose={closeModal}
      overlayClassName="ReactModal__Overlay"
      className="ReactModal__Content"
    >
      <Formik
        initialValues={{ ...currentBoard }}
        validationSchema={boardSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, values }) => (
          <Form autoComplete="off">
            <h4>{currentBoard.isAdd ? "Add Board" : "Edit Board"} </h4>
            <label htmlFor="boardName">
              Board Name
              <ErrorMessage
                name="boardName"
                render={(msg) => <span className="error">{msg}</span>}
              />
            </label>
            <Field
              name="boardName"
              type="text"
              autoFocus={true}
              className={getStyles(errors, "boardName")}
            />

            {user && (
              <div className="toggle">
                <Field type="checkbox" name="isPrivate" id="isPrivate" />
                <label htmlFor="isPrivate">
                  <div className="toggle__label-text">Is Private?</div>
                  <div
                    className="toggle__switch"
                    data-checked="Yes"
                    data-unchecked="No"
                  ></div>
                </label>
              </div>
            )}

            <div className="modal-action">
              <button
                type="button"
                className="button button--secoundary"
                onClick={closeModal}
              >
                Cancel
              </button>

              <button type="submit" className="button button--primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ReactModal>
  );
};
