import React from "react";
import { SubBookmark } from "./SubBookmark";
import { BookmarkControls } from "./BookmarkControls";
import { Draggable } from "react-beautiful-dnd";

export const Bookmark = ({ bookmark, categoryId, index }) => {
  const renderSubBookmarks = () => {
    return bookmark?.subBookmarks?.map((subBookmark, index) => (
      <SubBookmark key={subBookmark.subBookmarkId} subBookmark={subBookmark} />
    ));
  };

  const faviconUrl = `https://www.google.com/s2/favicons?domain=${bookmark.hostname}`;
  let bookmarkName;

  if (bookmark?.subBookmarks?.length > 0) {
    bookmarkName = (
      <div className="bookmark__name">{bookmark.bookmarkName}</div>
    );
  } else {
    bookmarkName = (
      <a
        className="bookmark__name"
        href={bookmark.bookmarkUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={faviconUrl}
          className="bookmark__favicon"
          alt="fav icon"
        ></img>
        {bookmark.bookmarkName}
      </a>
    );
  }

  return (
    <Draggable draggableId={bookmark.bookmarkId} index={index}>
      {(provided) => (
        <div
          className="bookmark"
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          {bookmarkName}

          <div className="bookmark__description">
            {bookmark.bookmarkDescription}
          </div>
          <div className="bookmark__childrens">{renderSubBookmarks()}</div>
          <BookmarkControls
            bookmark={bookmark}
            categoryId={categoryId}
            provided={provided}
          />
        </div>
      )}
    </Draggable>
  );
};
