import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { AddEditBoardModal } from "../components/AddEditBoardModal";
import { AddEditCategoryModal } from "../components/AddEditCategoryModal";
import { AddEditBookmarkModal } from "../components/AddEditBookmarkModal";

import { fetchBoard, saveBoardReorder } from "../actions/boardActions";

import { Header } from "../components/Header";
import { Category } from "../components/Category";
import { CategoryNew } from "../components/CategoryNew";
import { Loading } from "../components/Loading";

export const BoardPage = ({ match }) => {
  const dispatch = useDispatch();
  const boardId = match.params?.boardId;

  if (
    window.location.origin !== "https://linkley.jeminpro.com" &&
    window.location.origin !== "http://localhost:3000"
  ) {
    window.location.replace(
      `https://linkley.jeminpro.com${window.location.pathname}`
    );
  }

  useEffect(() => {
    dispatch(fetchBoard(boardId));
  }, [dispatch, boardId]);

  const hasErrors = useSelector((state) => state.board.hasErrors);
  const board = useSelector((state) => state.board.board);

  useEffect(() => {
    document.title = board?.boardName
      ? `Linkley - ${board.boardName}`
      : `Linkley`;
  }, [board]);

  const showAddEditBoardModal = useSelector(
    (state) => state.board.showAddEditBoardModal
  );

  const showAddEditCategoryModal = useSelector(
    (state) => state.board.showAddEditCategoryModal
  );

  const showAddEditBookmarkModal = useSelector(
    (state) => state.board.showAddEditBookmarkModal
  );

  const renderCategory = () => {
    if (hasErrors) return <p>Unable to display data.</p>;
    if (board?.categories == null) return <div />;

    return board.categories?.map((category, index) => (
      <Category key={category.categoryId} category={category} index={index} />
    ));
  };

  function onDragEnd(result) {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    dispatch(saveBoardReorder(result));
  }

  return (
    <section>
      <Header board={board} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="all-category"
          direction="horizontal"
          type="CATEGORY"
        >
          {(provided) => (
            <div
              className="categories"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {renderCategory()}
              {provided.placeholder}
              {board && <CategoryNew />}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Loading />
      {showAddEditBoardModal && <AddEditBoardModal />}
      {showAddEditCategoryModal && <AddEditCategoryModal />}
      {showAddEditBookmarkModal && <AddEditBookmarkModal />}
    </section>
  );
};
