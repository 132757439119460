import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { Bookmark } from "./Bookmark";
import { addNewBookmark } from "../actions/boardActions";
import { CategoryControls } from "./CategoryControls";
import { XIcon } from "@primer/octicons-react";

export const Category = ({ category, index }) => {
  const dispatch = useDispatch();

  const renderBookmarks = () => {
    return (
      <Droppable droppableId={category.categoryId} type="BOOKMARK">
        {(provided) => (
          <div
            className="bookmarks"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {category?.bookmarks?.map((bookmark, index) => (
              <Bookmark
                key={bookmark.bookmarkId}
                bookmark={bookmark}
                categoryId={category.categoryId}
                index={index}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  };

  return (
    <Draggable draggableId={category.categoryId} index={index}>
      {(provided) => (
        <div
          className="category"
          key={category.categoryId}
          index={index}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="category__header">
            {category.categoryName}
            <CategoryControls category={category} provided={provided} />
          </div>
          <div className="category__body">
            {renderBookmarks()}
            <div
              className="category__corner-ribbon"
              onClick={() => {
                dispatch(addNewBookmark(category));
              }}
            >
              <XIcon />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
