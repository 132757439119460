import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "react-modal";
import * as Yup from "yup";
import { getHostname } from "../utilities";
import { XIcon } from "@primer/octicons-react";
import { Formik, Field, Form, ErrorMessage, FieldArray, getIn } from "formik";
import {
  hideBookmarkModal,
  fetchNewUUID,
  saveBookmarkChanges,
} from "../actions/boardActions";

const subBookmarkSchema = Yup.object({
  subBookmarkName: Yup.string(),
  subBookmarkUrl: Yup.string(),
});

const bookmarkSchema = Yup.object({
  bookmarkName: Yup.string().required("Required"),
  bookmarkDescription: Yup.string(),
  bookmarkUrl: Yup.mixed()
    .when("showSubBookmarks", {
      is: false,
      then: Yup.string().required("Required"),
    })
    .when("showSubBookmarks", {
      is: true,
      then: Yup.mixed().notRequired(),
    }),
  subBookmarks: Yup.mixed()
    .when("showSubBookmarks", {
      is: false,
      then: Yup.mixed().notRequired(),
    })
    .when("showSubBookmarks", {
      is: true,
      then: Yup.array().of(subBookmarkSchema),
    }),
});

export const AddEditBookmarkModal = () => {
  const dispatch = useDispatch();
  const showAddEditBookmarkModal = useSelector(
    (state) => state.board.showAddEditBookmarkModal
  );
  const currentBookmark = useSelector((state) => state.board.currentBookmark);

  ReactModal.setAppElement("#root");

  const getStyles = (errors, fieldName) => {
    if (getIn(errors, fieldName)) {
      return "error-field";
    }
  };

  const checkAndAddNewSubBookmark = async (values, push) => {
    let shouldAddEmptySubBookmarks = true;
    for (let i = 0; i < values?.subBookmarks?.length; i++) {
      let subBookmark = values.subBookmarks[i];
      if (
        subBookmark.subBookmarkName === "" &&
        subBookmark.subBookmarkUrl === ""
      ) {
        shouldAddEmptySubBookmarks = false;
        break;
      }
    }
    const newUUID = await fetchNewUUID();
    if (shouldAddEmptySubBookmarks) {
      push({
        subBookmarkId: newUUID,
        subBookmarkName: "",
        subBookmarkUrl: "",
      });
    }
  };

  const addNewSubBookmark = () => {
    setTimeout(() => {
      const buttonAddNewSubBookmark = document.getElementById(
        "buttonAddNewSubBookmark"
      );
      if (buttonAddNewSubBookmark) {
        buttonAddNewSubBookmark.click();
      }
    }, 50);
  };

  if (currentBookmark?.subBookmarks?.length > 0) {
    addNewSubBookmark();
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    const details = {
      ...values,
      categoryId: currentBookmark.categoryId,
    };

    if (details.showSubBookmarks) {
      details.bookmarkUrl = null;

      for (let i = 0; i < details.subBookmarks.length; i++) {
        const subBookmark = details.subBookmarks[i];
        if (!subBookmark.subBookmarkName || !subBookmark.subBookmarkUrl) {
          details.subBookmarks.splice(i, 1);
          i--;
        }
      }
    } else {
      details.subBookmarks = [];

      if (
        !details.bookmarkUrl.startsWith("http") &&
        !details.bookmarkUrl.startsWith("//")
      ) {
        details.bookmarkUrl = `//${details.bookmarkUrl}`;
      }

      details.hostname = getHostname(details.bookmarkUrl);
    }

    dispatch(saveBookmarkChanges(details));
  };

  const closeModal = () => {
    dispatch(hideBookmarkModal());
  };

  return (
    <ReactModal
      isOpen={showAddEditBookmarkModal}
      onRequestClose={closeModal}
      overlayClassName="ReactModal__Overlay"
      className="ReactModal__Content"
    >
      <Formik
        initialValues={{ ...currentBookmark }}
        validationSchema={bookmarkSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, values, handleChange }) => (
          <Form autoComplete="off">
            <h4>{currentBookmark.isAdd ? "Add Bookmark" : "Edit Bookmark"} </h4>
            <label htmlFor="bookmarkName">
              Bookmark Name
              <ErrorMessage
                name="bookmarkName"
                render={(msg) => <span className="error">{msg}</span>}
              />
            </label>
            <Field
              name="bookmarkName"
              type="text"
              className={getStyles(errors, "bookmarkName")}
              autoFocus={true}
            />

            {!values.showSubBookmarks && (
              <>
                <label htmlFor="bookmarkUrl">
                  Url
                  <ErrorMessage
                    name="bookmarkUrl"
                    render={(msg) => <span className="error">{msg}</span>}
                  />
                </label>
                <Field
                  name="bookmarkUrl"
                  type="text"
                  className={getStyles(errors, "bookmarkUrl")}
                />
              </>
            )}

            <label htmlFor="bookmarkDescription">
              Dscription (Optional)
              <ErrorMessage
                name="bookmarkDescription"
                render={(msg) => <span className="error">{msg}</span>}
              />
            </label>
            <Field name="bookmarkDescription" as="textarea" />

            <div className="toggle">
              <Field
                type="checkbox"
                name="showSubBookmarks"
                id="showSubBookmarks"
                onChange={(e) => {
                  if (!values.showSubBookmarks) {
                    setTimeout(function () {
                      const buttonAddNewSubBookmark = document.getElementById(
                        "buttonAddNewSubBookmark"
                      );
                      if (buttonAddNewSubBookmark) {
                        buttonAddNewSubBookmark.click();
                      }
                    }, 100);
                  }

                  handleChange("showSubBookmarks")(e);
                }}
              />

              <label htmlFor="showSubBookmarks">
                <div className="toggle__label-text">Multiple urls?</div>
                <div
                  className="toggle__switch"
                  data-checked="Yes"
                  data-unchecked="No"
                ></div>
              </label>
            </div>

            {values.showSubBookmarks && (
              <>
                <h5>Sub bookmarks</h5>
                <table className="form-sub-bookmarks">
                  <thead>
                    <tr>
                      <th className="col-name">NAME</th>
                      <th className="col-url">URL</th>
                      <th className="col-delete"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name="subBookmarks">
                      {({ remove, push }) => (
                        <>
                          {values.subBookmarks?.length > 0 &&
                            values.subBookmarks.map((subBookmark, index) => (
                              <tr key={index}>
                                <td className="form-sub-bookmarks__name">
                                  <Field
                                    name={`subBookmarks.${index}.subBookmarkName`}
                                    type="text"
                                    onBlur={() => {
                                      checkAndAddNewSubBookmark(values, push);
                                    }}
                                    className={getStyles(
                                      errors,
                                      `subBookmarks.${index}.subBookmarkName`
                                    )}
                                  />
                                </td>
                                <td className="form-sub-bookmarks__url">
                                  <Field
                                    name={`subBookmarks[${index}].subBookmarkUrl`}
                                    type="text"
                                    onBlur={() => {
                                      checkAndAddNewSubBookmark(values, push);
                                    }}
                                    className={getStyles(
                                      errors,
                                      `subBookmarks.${index}.subBookmarkUrl`
                                    )}
                                  />
                                </td>

                                <td className="form-sub-bookmarks__remove">
                                  <button
                                    type="button"
                                    className="form-sub-bookmarks__buttons"
                                    onClick={async () => {
                                      remove(index);
                                    }}
                                  >
                                    <XIcon />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          <tr className="tr-invisible">
                            <td>
                              <button
                                id="buttonAddNewSubBookmark"
                                type="button"
                                onClick={async () => {
                                  checkAndAddNewSubBookmark(values, push);
                                }}
                              >
                                Invisible Add Button
                              </button>
                            </td>
                          </tr>
                        </>
                      )}
                    </FieldArray>
                  </tbody>
                </table>
              </>
            )}
            <div className="modal-action">
              <button
                type="button"
                className="button button--secoundary"
                onClick={closeModal}
              >
                Cancel
              </button>

              <button type="submit" className="button button--primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ReactModal>
  );
};
