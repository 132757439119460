import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBoard,
  editBoard,
  fetchUser,
  setTheme,
} from "../actions/boardActions";

import { PencilIcon, PlusIcon, TriangleDownIcon } from "@primer/octicons-react";

export const Header = ({ board }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const user = useSelector((state) => state.board.user);

  return (
    <header className="header">
      <BoardMenu board={board} user={user} />
      <Theme />
      <Login user={user} />
    </header>
  );
};

const BoardMenu = ({ board, user }) => {
  const dispatch = useDispatch();
  return (
    <div className="board-menu">
      <button className="board-name link-button">
        {board?.boardName} <TriangleDownIcon />
      </button>
      <div className="board-menu__content" role="menu">
        <div className="board-action">
          <button
            className="board-action__button right-border"
            onClick={() => {
              dispatch(editBoard(board));
            }}
          >
            <PencilIcon /> Edit
          </button>
          <button
            className="board-action__button"
            onClick={() => {
              dispatch(addBoard());
            }}
          >
            <PlusIcon /> Add
          </button>
        </div>
        {user?.boards?.map(
          (b) =>
            board?.boardName !== b.boardName && (
              <a className="menu-item" key={b._id} href={`/board/${b.boardId}`}>
                {b.boardName}
              </a>
            )
        )}
      </div>
    </div>
  );
};

const Theme = () => {
  const dispatch = useDispatch();

  const themes = useSelector((state) => state.board.themes);
  const selectedTheme = useSelector((state) => state.board.selectedTheme);

  useEffect(() => {
    for (const theme of themes) {
      if (selectedTheme === theme.themeName) {
        document.body.classList.add(selectedTheme);
      } else {
        document.body.classList.remove(theme.themeName);
      }
    }
  }, [themes, selectedTheme]);

  return (
    <div className="theme">
      <button className="theme__button link-button">
        <span className="theme__selected"></span> <TriangleDownIcon />
      </button>
      <ul className="theme__dropdown">
        {themes.map((theme, index) => (
          <li className="theme__item" key={index}>
            <button
              className={`${theme.themeName}__theme theme__item-color link-button`}
              onClick={() => {
                dispatch(setTheme(theme.themeName));
              }}
            >
              {theme.themeName}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Login = ({ user }) => {
  return (
    <div className="login">
      {user ? (
        <a
          href={`${process.env.REACT_APP_LINKLEY_API_BASE_URL}/auth/logout`}
          target="_self"
          className="login__link"
        >
          Logout
        </a>
      ) : (
        <a
          href={`${process.env.REACT_APP_LINKLEY_API_BASE_URL}/auth/google`}
          target="_self"
          className="login__link"
        >
          Login
        </a>
      )}
    </div>
  );
};
