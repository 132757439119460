import React from "react";
import { useDispatch } from "react-redux";
import { editBookmark, removeBookmarkChanges } from "../actions/boardActions";

import { GrabberIcon, PencilIcon, XIcon } from "@primer/octicons-react";

export const BookmarkControls = ({ bookmark, categoryId, provided }) => {
  const dispatch = useDispatch();

  return (
    <>
      <button className="btn btn--reorder" {...provided.dragHandleProps}>
        <GrabberIcon />
      </button>
      <button
        className="btn btn--edit"
        onClick={() => {
          dispatch(editBookmark(bookmark, categoryId));
        }}
      >
        <PencilIcon />
      </button>
      <button
        className="btn btn--remove"
        onClick={() => {
          dispatch(removeBookmarkChanges(bookmark, categoryId));
        }}
      >
        <XIcon />
      </button>
    </>
  );
};
