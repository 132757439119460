import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactModal from "react-modal";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage, getIn } from "formik";
import {
  hideCategoryModal,
  saveCategoryChanges,
} from "../actions/boardActions";

const categorySchema = Yup.object({
  categoryName: Yup.string().required("Required"),
});

export const AddEditCategoryModal = () => {
  const dispatch = useDispatch();
  const showAddEditCategoryModal = useSelector(
    (state) => state.board.showAddEditCategoryModal
  );
  const currentCategory = useSelector((state) => state.board.currentCategory);

  ReactModal.setAppElement("#root");

  const getStyles = (errors, fieldName) => {
    if (getIn(errors, fieldName)) {
      return "error-field";
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(saveCategoryChanges(values));
  };

  const closeModal = () => {
    dispatch(hideCategoryModal());
  };

  return (
    <ReactModal
      isOpen={showAddEditCategoryModal}
      onRequestClose={closeModal}
      overlayClassName="ReactModal__Overlay"
      className="ReactModal__Content"
    >
      <Formik
        initialValues={{ ...currentCategory }}
        validationSchema={categorySchema}
        onSubmit={handleSubmit}
      >
        {({ errors, values }) => (
          <Form autoComplete="off">
            <h4>{currentCategory.isAdd ? "Add Category" : "Edit Category"} </h4>
            <label htmlFor="categoryName">
              Category Name
              <ErrorMessage
                name="categoryName"
                render={(msg) => <span className="error">{msg}</span>}
              />
            </label>
            <Field
              name="categoryName"
              type="text"
              className={getStyles(errors, "categoryName")}
              autoFocus={true}
            />

            <div className="modal-action">
              <button
                type="button"
                className="button button--secoundary"
                onClick={closeModal}
              >
                Cancel
              </button>

              <button type="submit" className="button button--primary">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ReactModal>
  );
};
