import * as actions from "../actions/boardActions";

const emptyBoard = {
  boardId: "",
  boardName: "",
  isPrivate: false,
  isAdd: false,
};

const emptyCategory = {
  categoryId: "",
  categoryName: "",
  isAdd: false,
};

const emptyBookmark = {
  categoryId: "",
  bookmarkId: "",
  bookmarkName: "",
  bookmarkUrl: "",
  subBookmarks: [],
  isAdd: false,
  showSubBookmarks: false,
};

const themes = [
  { themeName: "light" },
  { themeName: "dark" },
  { themeName: "aqua" },
];

export const initialState = {
  linkley: {},
  loading: false,
  hasErrors: false,
  isEditMode: true,
  showAddEditBoardModal: false,
  showAddEditCategoryModal: false,
  showAddEditBookmarkModal: false,
  currentBoard: emptyBoard,
  currentCategory: emptyCategory,
  currentBookmark: emptyBookmark,
  user: null,
  themes: themes,
  selectedTheme: themes.find(
    (t) => t.themeName === localStorage.getItem("theme")
  )
    ? localStorage.getItem("theme")
    : "aqua",
};

export default function boardReducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_BOARD:
      return { ...state, loading: true };
    case actions.GET_BOARD_SUCCESS:
      return {
        ...state,
        board: action.payload,
        loading: false,
        hasErrors: false,
      };
    case actions.GET_BOARD_FAILURE:
      return { ...state, loading: false, hasErrors: true };

    case actions.ADD_BOARD:
      return addBoard(state, action);
    case actions.EDIT_BOARD:
      return editBoard(state, action);
    case actions.HIDE_BOARD_MODAL:
      return { ...state, showAddEditBoardModal: false };
    case actions.SAVE_BOARD_TO_STORE:
      return saveBoard(state, action);

    case actions.ADD_CATEGORY:
      return addCategory(state, action);
    case actions.EDIT_CATEGORY:
      return editCategory(state, action);
    case actions.HIDE_CATEGORY_MODAL:
      return { ...state, showAddEditCategoryModal: false };
    case actions.CREATE_NEW_CATEGORY_TO_STORE:
      return createNewCategoryToStore(state, action);
    case actions.UPDATE_CATEGORY_TO_STORE:
      return updateCategoryToStore(state, action);
    case actions.REMOVE_CATEGORY_FROM_STORE:
      return removeCategoryFromStore(state, action);

    case actions.ADD_BOOKMARK:
      return addBookmark(state, action);
    case actions.EDIT_BOOKMARK:
      return editBookmark(state, action);
    case actions.HIDE_BOOKAMRK_MODAL:
      return { ...state, showAddEditBookmarkModal: false };
    case actions.CREATE_NEW_BOOKMARK_TO_STORE:
      return createNewBookmarkToStore(state, action);
    case actions.UPDATE_BOOKMARK_TO_STORE:
      return updateBookmarkToStore(state, action);
    case actions.REMOVE_BOOKMARK_FROM_STORE:
      return removeBookmarkFromStore(state, action);

    case actions.GET_USER:
      return { ...state, user: null };
    case actions.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case actions.APPLY_BOARD_REORDER_TO_STORE:
      return applyBoardReorderToStore(state, action);

    case actions.SET_THEME:
      return { ...state, selectedTheme: action.payload };

    default:
      return state;
  }
}

function addBoard(state, action) {
  const newCurrentBoard = {
    ...emptyBoard,
    isAdd: true,
  };

  return {
    ...state,
    showAddEditBoardModal: true,
    currentBoard: { ...newCurrentBoard },
  };
}

function editBoard(state, action) {
  const editBoard = {
    ...action.payload,
    isAdd: false,
  };
  return {
    ...state,
    showAddEditBoardModal: true,
    currentBoard: { ...editBoard },
  };
}

function saveBoard(state, action) {
  const board = {
    ...state.board,
    boardName: action.payload.boardName,
    isPrivate: action.payload.isPrivate,
  };
  return {
    ...state,
    board,
  };
}

function addCategory(state, action) {
  const newCurrentCategory = {
    ...emptyCategory,
    categoryId: action.payload,
    isAdd: true,
  };

  return {
    ...state,
    showAddEditCategoryModal: true,
    currentCategory: { ...newCurrentCategory },
  };
}

function editCategory(state, action) {
  const editCategory = {
    ...action.payload,
    isAdd: false,
  };
  return {
    ...state,
    showAddEditCategoryModal: true,
    currentCategory: { ...editCategory },
  };
}

function createNewCategoryToStore(state, action) {
  const board =
    state.board?.categories?.length > 0
      ? {
          ...state.board,
          categories: [...state.board.categories, action.payload],
        }
      : {
          ...state.board,
          categories: [action.payload],
        };
  return {
    ...state,
    board,
    loading: true,
  };
}

function updateCategoryToStore(state, action) {
  const index = state.board.categories.findIndex(
    (c) => c.categoryId === action.payload.categoryId
  );

  const newCategories = [...state.board.categories];
  newCategories[index] = action.payload;

  return {
    ...state,
    board: { ...state.board, categories: newCategories },
  };
}

function removeCategoryFromStore(state, action) {
  const newCategories = state.board.categories.filter(
    (c) => c.categoryId !== action.payload.categoryId
  );

  return {
    ...state,
    board: { ...state.board, categories: newCategories },
    loading: true,
  };
}

function addBookmark(state, action) {
  const newCurrentBookmark = {
    ...emptyBookmark,
    ...action.payload,
    isAdd: true,
  };
  return {
    ...state,
    showAddEditBookmarkModal: true,
    currentBookmark: { ...newCurrentBookmark },
  };
}

function editBookmark(state, action) {
  const editBookmark = {
    ...action.payload,
    isAdd: false,
    showSubBookmarks: action.payload?.subBookmarks?.length > 0,
  };
  return {
    ...state,
    showAddEditBookmarkModal: true,
    currentBookmark: { ...editBookmark },
  };
}

function createNewBookmarkToStore(state, action) {
  const categoryIndex = state.board.categories.findIndex(
    (c) => c.categoryId === action.payload.categoryId
  );

  const bookmark = { ...action.payload };
  delete bookmark.isAdd;
  delete bookmark.categoryId;
  delete bookmark.showSubBookmarks;

  const newCategories = [...state.board.categories];
  const category = newCategories[categoryIndex];

  if (!category?.bookmarks) {
    category.bookmarks = [];
  }

  category.bookmarks.push(bookmark);

  return {
    ...state,
    board: { ...state.board, categories: newCategories },
    loading: true,
  };
}

function updateBookmarkToStore(state, action) {
  const categoryIndex = state.board.categories.findIndex(
    (c) => c.categoryId === action.payload.categoryId
  );

  const newCategories = [...state.board.categories];
  const category = newCategories[categoryIndex];

  const bookmarkIndex = category.bookmarks.findIndex(
    (b) => b.bookmarkId === action.payload.bookmarkId
  );

  const bookmark = { ...action.payload };
  delete bookmark.isAdd;
  delete bookmark.categoryId;
  delete bookmark.showSubBookmarks;

  const newBookmarks = [...category.bookmarks];
  newBookmarks[bookmarkIndex] = bookmark;
  category.bookmarks = newBookmarks;

  return {
    ...state,
    board: { ...state.board, categories: newCategories },
    loading: true,
  };
}

function removeBookmarkFromStore(state, action) {
  const categoryIndex = state.board.categories.findIndex(
    (c) => c.categoryId === action.payload.categoryId
  );

  const newCategories = [...state.board.categories];
  const category = newCategories[categoryIndex];

  const newBookmarks = category.bookmarks.filter(
    (c) => c.bookmarkId !== action.payload.bookmarkId
  );
  category.bookmarks = newBookmarks;

  return {
    ...state,
    board: { ...state.board, categories: newCategories },
  };
}

function applyBoardReorderToStore(state, action) {
  const { type } = { ...action.payload };

  switch (type) {
    case "BOOKMARK":
      return applyBookmarkReorderToStore(state, action);
    case "CATEGORY":
      return applyCategoryReorderToStore(state, action);

    default:
      return { ...state, loading: true };
  }
}

function applyBookmarkReorderToStore(state, action) {
  const { destination, source, draggableId } = { ...action.payload };

  const newCategories = [...state.board.categories];

  const startCategoryIndex = state.board.categories.findIndex(
    (c) => c.categoryId === source.droppableId
  );

  const finishCategoryIndex = state.board.categories.findIndex(
    (c) => c.categoryId === destination.droppableId
  );

  const start = newCategories.find((c) => c.categoryId === source.droppableId);
  const finish = newCategories.find(
    (c) => c.categoryId === destination.droppableId
  );

  const draggedBookmark = start.bookmarks.find(
    (b) => b.bookmarkId === draggableId
  );

  const newBookmarksFromStart = [...start.bookmarks];

  // moved within the list
  if (startCategoryIndex === finishCategoryIndex) {
    newBookmarksFromStart.splice(source.index, 1);
    newBookmarksFromStart.splice(destination.index, 0, draggedBookmark);

    const newCategory = {
      ...start,
      bookmarks: newBookmarksFromStart,
    };

    newCategories[startCategoryIndex] = newCategory;

    return {
      ...state,
      board: { ...state.board, categories: newCategories },
    };
  }

  // moved to another list
  const newBookmarksFromFinish = finish.bookmarks ? [...finish.bookmarks] : [];

  newBookmarksFromStart.splice(source.index, 1);
  newBookmarksFromFinish.splice(destination.index, 0, draggedBookmark);

  const newStartCategory = {
    ...start,
    bookmarks: newBookmarksFromStart,
  };

  newCategories[startCategoryIndex] = newStartCategory;

  const newFinishCategory = {
    ...finish,
    bookmarks: newBookmarksFromFinish,
  };

  newCategories[finishCategoryIndex] = newFinishCategory;

  return {
    ...state,
    board: { ...state.board, categories: newCategories },
  };
}

function applyCategoryReorderToStore(state, action) {
  const { destination, source, draggableId } = { ...action.payload };

  const newCategories = [...state.board.categories];

  const draggedBookmark = newCategories.find(
    (c) => c.categoryId === draggableId
  );

  newCategories.splice(source.index, 1);
  newCategories.splice(destination.index, 0, draggedBookmark);

  return {
    ...state,
    board: { ...state.board, categories: newCategories, loading: true },
  };
}
