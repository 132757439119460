import React from "react";
import { useDispatch } from "react-redux";
import { addNewCategory } from "../actions/boardActions";
import { PlusIcon } from "@primer/octicons-react";

export const CategoryNew = () => {
  const dispatch = useDispatch();

  return (
    <div className="category category--empty">
      <button
        className="button-category-add"
        onClick={() => {
          dispatch(addNewCategory());
        }}
      >
        <PlusIcon />
      </button>
    </div>
  );
};
